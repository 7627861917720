import AuthLayout from "../../layout/auth/auth";
import {Route, Routes} from "react-router-dom";
import Login from "./Login/Login";
import ForgetPassword from "./ForgetPassoword/ForgetPassword";
import NewPassword from "./ForgetPassoword/NewPassword";
import OTP from "./ForgetPassoword/OTP";

export default function AuthEntry({className, ...props}) {

    return (
        <AuthLayout>
            <Routes>
                <Route path="/login" element={<Login/>}/>

                <Route path="/forget-password" element={<ForgetPassword/>}/>
                <Route path="/otp" element={<OTP/>}/>
                <Route path="/new-password" element={<NewPassword/>}/>

            </Routes>


        </AuthLayout>

    )
}