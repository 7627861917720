


 export default function TableBody({className,...props}) {

     return (
         <tbody className={` ${className}  bg-white divide-y dark:divide-darkTheme-700 dark:bg-darkTheme-800 text-darkTheme-700 dark:text-darkTheme-400`}>
             {
                    props.children
             }
         </tbody>

     )
 }