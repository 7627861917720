


 export default function FormCard({className,...props}) {

     return (
         <div className={`py-2 px-2 mt-2 bg-gray-100 dark:bg-darkTheme-800 rounded-lg   ${className}`}>
             {
                    props.children
             }
         </div>

     )
 }