import {useNavigate} from "react-router-dom";
import {getUri, truchateString} from "../../../utils/utils";
import DeleteModalButton from "../../../components/base/delete-button/delete-modal-button";
import {FiDelete} from "react-icons/fi";
import {LuDelete} from "react-icons/lu";
import {BiRecycle} from "react-icons/bi";
import {AiFillDelete} from "react-icons/ai";
import URLS from "../../../API/Axios/URLS";

export function BlogCardAnimation({className, ...props}) {
    return (
        <div className={`animate-pulse ${className} p-[0.75rem]`}>
            <div className={"w-full h-[200px] mb-2 rounded-md bg-gray-200"}></div>
            <div className={"flex flex-col gap-1  "}>
                <div className={"w-3/4 h-4 bg-gray-200"}></div>
                <div className={"w-1/2 h-4 bg-gray-200"}></div>
                <div className={"w-1/3 h-4 bg-gray-200"}></div>
            </div>
        </div>
    )
}


export default function BlogCard({className, title,refreshCallBack, metaDescription, date, thumbnail, variant, ...props}) {
    let image = thumbnail
    let style = variant && variant === "card" ? "rounded-md bg-white shadow-sm p-[0.75rem]" : "";
    const navigate = useNavigate();
    return (
        <div className={` ${className} ${style} gap-2 flex flex-col`}>
            <img
                src={image}
                alt={"blog"}
                className={"w-full h-[200px] object-cover "}
            />

            <div className={"flex flex-col gap-1  "}>
                <p className={"font-semibold text-xs text-purple-900"}>
                    {date}
                </p>
                <p className={"font-bold dark:text-white"}>
                    {title}
                </p>
                <p className={"text-sm dark:text-white"}>
                    {truchateString(metaDescription, 100)}
                </p>

                <div className={"flex flex-row  items-center gap-2"}>
                    <div
                        onClick={() => {
                            navigate(`/app/blogs/update/${title}`)

                        }}
                        className={"cursor-pointer text-purple-900 font-semibold"}>
                        Edit
                    </div>
                    <DeleteModalButton
                        title={"Delete Blog"}
                        url={URLS.blogs}
                        params={{
                            title: title
                        }}
                        highlightText={title}
                        description={` will be deleted permanently. Press confirm to delete the blog.`}
                        onDeleteCallBack={() => {
                            if (refreshCallBack) {
                                refreshCallBack()
                            }
                        }}

                        className={""}>

                        <AiFillDelete className={"m-auto text-purple-900"}/>

                    </DeleteModalButton>
                </div>


            </div>
        </div>

    )
}