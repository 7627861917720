import AdminLayout from "../layout/admin/admin";
import {Route, Routes} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Properties from "./Properties";
import Guide from "./guide";
import BlogsEntry from "./blogs/blogs-entry";
import SettingIndex from "./Setting";
import NewsLetterSubscriber from "./news-letter-subscriber/news-letter-subscriber";
import User from "./users/user";
import Material from "./material/material";


export default function DemoScreenEntry({className, ...props}) {

    return (
        <AdminLayout>
            <Routes >
                <Route path="/app/dashboard" element={<Dashboard/>}/>
                {/*<Route path="/app/properties/*" element={<Properties/>}/>*/}
                <Route path="/app/guide/*" element={<Guide/>}/>
                <Route path="/app/blogs/*" element={<BlogsEntry/>}/>
                <Route path="/app/settings/*" element={<SettingIndex/>}/>
                <Route path="/app/user/*" element={<User/>}/>
                <Route path="/app/material/*" element={<Material/>}/>
                <Route path="/app/news-letter-subscriber/*" element={<NewsLetterSubscriber/>}/>
            </Routes>
        </AdminLayout>

    )
}