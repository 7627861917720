import {createSlice} from "@reduxjs/toolkit";

const initalState = {
    text: "",
}


const SearchInputSlice = createSlice({
    name: "SearchInput",
    initialState: initalState,
    reducers: {
        setSearchInput: (state, action) => {
            state ={

                text: action.payload
            }
            return state
        },

    }
})


export const {setSearchInput} = SearchInputSlice.actions
export default SearchInputSlice.reducer