import Badge from "../../Badges/Badge";
import DeleteModalButton from "../delete-button/delete-modal-button";
import {MdDelete} from "react-icons/md";
import URLS from "../../../API/Axios/URLS";

export default function MembershipCard({className, ...props}) {

    return (
        <div
            className={`flex dark:text-white p-3    flex-col gap-1 rounded-md shadow-md bg-gray-50 border-[1px]   dark:bg-darkBgSecondary dark:border-darkBgSecondary  ${className}`}>


            <div className={"inline-flex justify-between items-center"}>
                <p className={"font-semibold text-lg"}>
                    #{props?.id}   {props?.package?.name} - <span className={" text-xs opacity-70"}>
                 {props?.package?.type}
             </span>
                </p>

                <p className={"font-bold"}>
                    {props?.amount}$
                </p>
            </div>
            <div className={"grid grid-cols-2 mt-3"}>
                <p className={"font-semibold"}>
                    Start Date
                </p>
                <p className={"opacity-70 text-end"}>
                    {props?.start_date}
                </p>
                <p className={"font-semibold"}>
                    End Date
                </p>
                <p className={"opacity-70 text-end"}>
                    {props?.end_date}
                </p>

            </div>

            <div className={"flex flex-row justify-between items-center     mt-3"}>
                <div className={"inline-flex gap-2 text-xs"}>
                    <p className={"font-semibold"}>
                        Created At
                    </p>
                    <p className={"opacity-70"}>
                        {props?.created_at}
                    </p>
                </div>
                <div className={"flex-1"}/>
                <div className={"flex flex-row justify-end"}>
                    {props?.currently_active ?
                        <Badge type={"SOLD"}>
                            <p className={"px-7"}>
                                Active

                            </p>

                        </Badge> :
                        <Badge type={"UN_LISTED"}>
                            <p className={"mx-7"}>
                                Inactive
                            </p>
                        </Badge>

                    }
                </div>
                <div className={"flex ml-2 flex-row justify-end"}>
                    <DeleteModalButton
                        title={"Delete Membership"}
                        url={URLS.UserMembershipsCrud}
                        params={{
                            id: props?.id
                        }}
                        highlightText={"Membership with id " + props?.id}
                        description={` will be deleted permanently. Press confirm to delete the membership.`}
                        onDeleteCallBack={() => {
                            if (props?.onDeleteCallBack) {
                                props?.onDeleteCallBack()
                            }
                        }}
                    >
                        <MdDelete/>
                    </DeleteModalButton>

                </div>
            </div>


        </div>

    )
}