export default function TableContainer({className, ...props}) {

    return (
        <div className={` ${className} w-full overflow-auto rounded-lg bg-white dark:bg-darkTheme-800 border-[1px] dark:border-gray-700  `}>
            <div className={` ${className} w-full whitespace-nowrap  overflow-x-auto `}>
                {
                    props.children
                }

            </div>
        </div>

    )
}