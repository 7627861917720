import SearchInput from "../../../components/fields/search-input/search-input";
import {useNavigate} from "react-router-dom";

export default function BlogHeroSection({className, ...props}) {
    const navigate = useNavigate();
    return (
        <div className={`flex justify-center items-center w-full py-10  ${className}`}>
            <div className={"w-full xl:w-1/2"}>
                <SearchInput
                    className={"w-full"}
                    onAdd={() => {
                        navigate("/app/blogs/new-blog")
                    }}
                    placeholder={"Search Blogs"}

                    {...props}

                />
            </div>


        </div>

    )
}