import {useEffect, useState} from "react";
import axiosInstance from "../API/Axios/AxiosConfig";


export default function useForm(obj) {
    const [form, setForm] = useState(obj);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    function setFormData(obj) {
        setForm({
            ...form, ...obj
        })

    }

    async function post(url,transformData=null) {
        setError({});
        setLoading(true);
        let fromData = form;
        if (transformData)
            fromData = transformData({...form})
        return new Promise(async (resolve, reject) => {
            await axiosInstance.post(url, fromData).then(response => {
                resolve(response);
            }).catch(error => {
                if (error.response.status === 400) {
                    setError(error?.response?.data?.error);
                } else reject(error);

            }).finally(() => {
                setLoading(false);
            });
        });
    }

    function put(url,transformData=null) {
        setError({});
        setLoading(true);
        let fromData = form;
        if (transformData)
            fromData = transformData({...form})
        return new Promise((resolve, reject) => {
            axiosInstance.put(url, fromData).then(response => {
                resolve(response);
            }).catch(error => {
                if (error.status === 400) {
                    setError(error?.response?.data?.error);
                }
                reject(error);
            }).finally(() => {
                setLoading(false);
            });
        });
    }

    function get(url) {
        setError({});
        setLoading(true);
        return new Promise((resolve, reject) => {
            axiosInstance.get(url, {
                params: form
            }).then(response => {
                resolve(response);
            }).catch(error => {
                if (error.status === 400) {
                    setError(error?.response?.data?.error);
                }
                reject(error);
            }).finally(() => {
                setLoading(false);
            });
        });
    }

    let actions = {
        post: post, put: put, get: get
    }


    return [form, error, loading, setFormData, actions];
}