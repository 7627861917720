import Icon from "../../Icon/Icon";

export default function SearchInput({outerClassName,className,onAdd, ...props}) {

    return (
        <div className={"rounded-md dark:bg-darkBgSecondary justify-center items-center px-4 p-3 shadow-md bg-white w-full flex flex-row gap-2"}>
            <Icon
                className={"h-5"}
                name={"search-black"} />
            <input
                className={`${className} w-full outline-none border-none bg-transparent`}
                {...props}
            />
            <Icon
                title={"add"}
                onClick={onAdd}
                className={"h-6 cursor-pointer"}
                name={"plus"} />
        </div>

    )
}