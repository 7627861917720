import loginOffice from "../../assets/img/login-office.jpeg";
import loginOfficeDark from "../../assets/img/login-office-dark.jpeg";

export default function AuthLayout({className, ...props}) {

    return (
        <div>

            <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-darkTheme-900">

                <main className={`mx-auto min-h-screen`}>
                    <div className="relative flex">
                        <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
                            <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                               <div className={"justify-center items-center flex"}>
                                   <div className="mt-16 mb-16 flex h-full w-full  justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">

                                       {
                                        props.children

                                   }
                               </div>
                               </div>
                                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                                    <div
                                        className="absolute flex h-full w-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
                                        style={{ backgroundImage: `url(${loginOfficeDark})` }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </div>
        </div>

    )
}