import {useEffect, useState} from "react";
import axiosInstance from "../API/Axios/AxiosConfig";


export default function useGet(uri, params = {}) {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    function loadData() {
        axiosInstance.get(uri, {
            params: params
        }).then(res => {
            setData(res.data?.data)
            setLoading(false)
        })
            .catch(err => {
                setError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        loadData()

    }, [])

    return [data, loading, error, loadData]
}