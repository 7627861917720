import BaseModal from "../base-modal/base-modal";
import axios from "axios";
import axiosInstance from "../../API/Axios/AxiosConfig";
import {toast} from "react-toastify";


export default function DeleteConfirmationModal({
                                                    params,
                                                    className, isOpen, setIsOpen,
                                                    title,
                                                    description,
                                                    onDeleteCallBack,
                                                    url,
                                                    highlightText,
                                                    ...props
                                                }) {

    function deleteTheRecord() {
        axiosInstance.delete(url, {
            params: {
                ...params
            }
        }).then(res => {
            toast(`Deleted Successfully`, {
                type: "success",
                toastId: "delete-success"
            })
            if (onDeleteCallBack)
                onDeleteCallBack()
            setIsOpen(false)
        }).catch(err => {
            toast.error(err.response.data.detail || "Something went wrong");
        })
    }

    return (
        <BaseModal innerClassName={"dark:bg-black  lg:w-1/2"} isBoxOpen={isOpen} title={title} autoClose={true}
                   setIsBoxOpen={setIsOpen}>
            <div className={"flex justify-center pt-5 items-center gap-10 flex-col"}>
                <p className={'text-black font-semibold dark:text-white'}>
                    <span className={"text-purple-900"}>{highlightText}</span> {description}
                </p>
                <div className={"flex w-full gap-4 flex-row-reverse  "}>

                    <button
                        onClick={() => setIsOpen(false)}
                        className={"w-[100px] h-[30px] rounded-md border-[1px] border-gray-300"}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={deleteTheRecord}
                        className={"w-[100px] h-[30px] rounded-md bg-purple-900 text-white"}

                    >
                        Confirm
                    </button>
                </div>
            </div>

        </BaseModal>


    )
}