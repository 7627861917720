import BaseModal from "../base-modal/base-modal";
import useListApi from "../../Hooks/useListApi";
import URLS from "../../API/Axios/URLS";
import FormItem from "../../components/Form/FormItem/FormItem";
import useForm from "../../Hooks/useForm";
import FormButton from "../../components/Form/Button/FormButton";
import moment from "moment";
import {useEffect} from "react";
import {toast} from "react-toastify";

export default function NewMembershipModal({className, onSuccess, isOpen, setIsOpen, user_id, ...props}) {
    const [pacakges] = useListApi(URLS.Packages, {
        type: "VIP"
    })

    const [form, error, loading, setFormData, actions] = useForm({
        user: user_id,
        package: null,
        start_date: new Date().toISOString().split("T")[0]
    })

    useEffect(() => {

        setFormData({
            user: parseInt(user_id),
            package: null,
            start_date: new Date().toISOString().split("T")[0]
        })
    }, [isOpen]);


    function submitForm() {
        actions.post(URLS.UserMembershipsCrud, (data) => {
            console.log(data)
            return {
                ...data,
                start_date: moment(data.start_date).format("YYYY-MM-DD")
            }
        }).then(response => {
            if (onSuccess) onSuccess()
            setIsOpen(false)
            toast("Membership assigned successfully", {
                type: "success",
                toastId: "membership-assigned"
            })
        }).catch(error => {
            toast("Error while assigning membership", {
                type: "error",
                toastId: "membership-assigned"
            })
        })

    }

    return (
        <BaseModal innerClassName={"dark:bg-black  lg:w-1/2"} isBoxOpen={isOpen} title={"Assign Membership"}
                   autoClose={true}
                   setIsBoxOpen={setIsOpen}>
            <div className={"py-7"}>

                <form className={"flex flex-col gap-4"}>
                    <FormItem
                        label={"Start Date"}
                        message={error?.start_date}
                    >
                        <input
                            type={"date"}
                            value={form.start_date}
                            onChange={(e) => {
                                setFormData({
                                    ...form,
                                    start_date: e.target.value
                                })
                            }}
                        />
                    </FormItem>

                    <FormItem
                        label={"Select Package"}

                        message={error?.package}
                    >
                        <select
                            onChange={(e) => {
                                // setFormData({
                                //     ...form,
                                //     package: e.target.value
                                // })
                                console.log(e.target.value)
                                setFormData({
                                    ...form,
                                    package: parseInt(e.target.value)
                                })
                                //     parseInt(e.target.value)
                            }}

                        >
                            {form?.package === null && <option selected={true} value={null}>Select Package</option>}
                            {
                                pacakges?.results?.map((packagee, index) => {
                                    return <option key={index}
                                                   selected={form.package === packagee.id}
                                                   value={packagee.id}>{packagee.name} - {packagee?.days}days </option>
                                })
                            }
                        </select>
                    </FormItem>

                    <FormButton
                        onClick={submitForm}
                        loading={loading}
                    >
                        Assign Membership
                    </FormButton>

                </form>
            </div>
        </BaseModal>

    )
}