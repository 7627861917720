import React, {useState, useEffect} from 'react'
import CTA from "../../components/utils/CTA";
import IconCard from "../../components/Cards/IconCards/IconCard";
import {BiLogoAndroid, BiUser} from "react-icons/bi";
import ClientTable from "./tables/ClientTable/ClientTable";
import PageTitle from "../../components/utils/PageTitle";
import ChartCard from "../../components/Cards/ChartCard/ChartCard";
import {Bar, Doughnut, Line} from "react-chartjs-2";
import {
    barLegends,
    barOptions,
    doughnutLegends,
    doughnutOptions,
    lineLegends,
    lineOptions
} from "./variabales/chartData";
import ChartLegend from "../../components/utils/ChartLegend";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    ArcElement,
    BarElement,
    Legend,
} from 'chart.js';
import {HiOutlineZoomIn} from "react-icons/hi";
import {MdCardMembership, MdCropFree} from "react-icons/md";
import {LiaFreebsd} from "react-icons/lia";
import useGet from "../../Hooks/useGet";
import URLS from "../../API/Axios/URLS";
import {GiCash} from "react-icons/gi";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement
);


export default function Dashboard() {
    const [zoomChart, setZoomChart] = useState(false)
    const [data, loading] = useGet(URLS.dashboard)

    console.log(data)

    function getPracticedQuestionLabelAndData(PracticedQuestionS) {
        if (!PracticedQuestionS) return {
            labels: [],
            data: []
        }
        let labels = [];
        let data2 = [];
        PracticedQuestionS?.forEach((item) => {
            labels.push(item?.date);
            data2.push(item?.total)
        });

        return {
            labels: labels,
            data: data2
        }
    }


    return (
        <>
            <h1 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">Dashboard</h1>

            <div className={"grid gap-2  grid-cols-1 md:grid-cols-2  xl:grid-cols-4"}>
                <IconCard
                    title={"Total Users"}
                    icon={<BiUser className={"text-2xl"}/>}
                    value={data?.cardStats?.totalUsers}
                />
                <IconCard
                    title={"Free Users"}
                    color={"#5813e1"}
                    icon={<LiaFreebsd className={"text-2xl"}/>}
                    value={data?.cardStats?.freeUsers}
                />
                <IconCard
                    title={"Premium Users"}
                    color={"#fc0a8e"}

                    icon={<MdCardMembership className={"text-2xl"}/>}
                    value={data?.cardStats?.premiumUsers}
                />
                <IconCard
                    title={"Revenue 30 days"}
                    color={"#f15252"}

                    icon={<GiCash className={"text-2xl"}/>}
                    value={data?.cardStats?.totalRevenue + "$"}
                />

            </div>
            <div className={"flex flex-row justify-center items-center"}>
                <PageTitle className={"flex-1"}>Charts</PageTitle>
                <HiOutlineZoomIn onClick={() => setZoomChart(!zoomChart)}
                                 className={"text-xl cursor-pointer text-gray-700 dark:text-gray-200"}/>

            </div>
            <div className={`grid gap-6 mb-8 ${zoomChart ? "" : "md:grid-cols-2"}`}>
                {
                    loading && <>
                    <div className={"rounded-md h-[400px] bg-gray-200 dark:bg-darkBgSecondary animate-pulse shadow-md "}>
                    </div>
                        <div className={"rounded-md h-[400px]  bg-gray-200 dark:bg-darkBgSecondary animate-pulse shadow-md "}>
                        </div>

                    </>
                }
                {data?.participatedQuestionsStats && <ChartCard title="Daily Paraticed Questions">
                    <Line {...lineOptions}
                          data={{
                              ...lineOptions.data,
                              labels: getPracticedQuestionLabelAndData(data?.participatedQuestionsStats).labels,
                              datasets: [
                                  {
                                      label: 'Practiced Questions',
                                      data: getPracticedQuestionLabelAndData(data?.participatedQuestionsStats).data,
                                      backgroundColor: '#7e3af2',
                                      borderColor: '#7e3af2',

                                  }
                              ]

                          }}
                    />

                </ChartCard>}
                {data?.revenueStats && <ChartCard title="Revenue 30 days">
                    <Bar {...barOptions}
                         data={{
                             ...barOptions.data,
                             labels: getPracticedQuestionLabelAndData(data?.revenueStats).labels,
                             datasets: [
                                 {
                                     label: 'Revenue',
                                     data: getPracticedQuestionLabelAndData(data?.revenueStats).data,
                                     backgroundColor: '#7e3af2',
                                     borderColor: '#7e3af2',

                                 }]

                         }}
                    />

                </ChartCard>}
            </div>
        </>
    )
}