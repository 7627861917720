import {Link} from "react-router-dom";
import {useState} from "react";
import {IoIosArrowDown} from "react-icons/io";
// import TransitionUpToDown from "../Animaiton/Transitions/TransitionUpToDown";
import Transition from "../Animaiton/Transitions/Transition";


export default function SidebarSubMenu({className, route, ...props}) {
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(true)

    function handleDropdownMenuClick() {
        setIsDropdownMenuOpen(!isDropdownMenuOpen)
    }

    return (

        <li className="relative px-6 py-3" key={route.name}>
            <button
                className="inline-flex items-center justify-between w-full text-sm font-semibold

                 transition-colors duration-150 hover:text-gray-800 dark:text-gray-100 dark:hover:text-gray-300"
                onClick={handleDropdownMenuClick}
                aria-haspopup="true"
            >
             <span className="inline-flex items-center">
                <route.icon className="w-4 h-4" aria-hidden="true"/>
                <p className="ml-4 flex-1">{route.name}</p>
             </span>
                <IoIosArrowDown className="w-4 h-4 ml-1" aria-hidden="true"/>

            </button>
            <Transition
                show={isDropdownMenuOpen}
                enter="transition-all ease-in-out duration-300"
                enterFrom="opacity-25 max-h-0"
                enterTo="opacity-100 max-h-xl"
                leave="transition-all ease-in-out duration-300"
                leaveFrom="opacity-100 max-h-xl"
                leaveTo="opacity-0 max-h-0"

            >
                <ul
                    className="p-2 mt-2 space-y-2  overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
                    aria-label="submenu"
                >
                    {route.routes.map((r) => (
                        <li
                            className="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                            key={r.name}
                        >
                            <Link className="w-full" to={r.path}>
                                {r.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </Transition>
        </li>

    )
}