export const isLocalhost = window.location.hostname === "localhost";

export const isProduction = !isLocalhost;

export const PRODUCTION_URL = "https://api.detstudy.com/det-admin/";
export const DEVELOPMENT_URL = "http://localhost:8000/det-admin/";
export const BASE_URL = isProduction ? PRODUCTION_URL : DEVELOPMENT_URL;
export const BASE_IMAGE_URL = isProduction ? "https://api.detstudy.com/" : "http://localhost:8000/";

export const CURRENCY = "$";
export const APP_NAME = "Det Study";
export const GOOGLE_API_KEY = "347553604450-p5gjb68l8sataq0m6o3memmhun0u49st.apps.googleusercontent.com";


export const GOOGLE_MAP_API_KEY = "AIzaSyDPvCZmeGuN-y_pJ3ug7PYrwp6BHyKnNUY";