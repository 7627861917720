import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
import PageTitle from "../../../components/utils/PageTitle";
import {Link} from "react-router-dom";
import {AiOutlineAppstoreAdd} from "react-icons/ai";
import Table from "../../../components/Table/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableCell from "../../../components/Table/TableCell";
import TableBody from "../../../components/Table/TableBody";
import TableLoadingRow from "../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../components/Table/TableEmpty";
import TableRow from "../../../components/Table/TableRow";
import ReactStars from "react-rating-stars-component";
import {MdDelete, MdEditNote} from "react-icons/md";
import TableFooter from "../../../components/Table/TableFooter";
import Paginator from "../../../components/Table/Paginator";
import TableContainer from "../../../components/Table/TableContainer";
import {getUri} from "../../../utils/utils";
import DeleteModalButton from "../../../components/base/delete-button/delete-modal-button";


export default function MaterialTable({className,...props}) {
    let [materialData, filter, isLoading, materialActions] = useListApi(URLS.MaterialList);

    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            <PageTitle>
                Materials
            </PageTitle>
            <div className={"w-full flex mb-10 justify-end items-center"}>
                <Link title={"Add material"}
                      className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                      to={"/app/material/add"}>
                    <AiOutlineAppstoreAdd className={"text-base"}/>
                </Link>
            </div>

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Title</TableCell>
                            <TableCell>File</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Actions</TableCell>
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {materialData?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && materialData?.results.map((materials, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <div>
                                            <p className="text-base text-gray-600  dark:text-gray-400">{materials.title}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <a
                                        href={getUri(materials?.file?.file)}
                                        target="_blank"
                                        className="text-sm text-gray-600  dark:text-gray-400">
                                        View File

                                    </a>

                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{materials?.is_free?
                                    "Free": "Elite Pass Required"
                                    }</span>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{materials?.created_at}</span>
                                </TableCell>
                                <TableCell>
                                    <div className={"flex gap-2"}>
                                        <Link to={`/app/material/edit/${materials.id}`}>
                                            <MdEditNote
                                                title={"edit"} className={"cursor-pointer"}/>
                                        </Link>
                                        <DeleteModalButton
                                            title={"Delete Material"}
                                            url={URLS.MaterialCrud}
                                            params={{
                                                id: materials?.id
                                            }}
                                            highlightText={"Material  " + materials?.title}
                                            description={` will be deleted permanently. Press confirm to delete the Material.`}
                                            onDeleteCallBack={() => {
                                                materialActions.load()
                                            }}
                                        >
                                            <MdDelete/>
                                        </DeleteModalButton>

                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {materialData?.count} entries
                    </p>
                    <Paginator
                        currentPage={materialData?.currentPage}
                        totalPages={materialData?.totalPages}
                        onNext={(e) => {
                            materialActions.loadNextPage()
                        }}
                        onPrev={(e) => materialActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            materialActions.loadSpecificPage(e)
                        }}
                    />

                </TableFooter>

            </TableContainer>

        </div>

    )
}