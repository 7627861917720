import {BASE_IMAGE_URL, BASE_URL} from "../settings/settings";


export const getUri = (uri) => {
    const DEFAULT = "https://i.postimg.cc/6qf1gyq1/male-man-person-people-avatar-white-tone-icon-159365.png"


    try {
        if (uri === null || uri === undefined) {
            return DEFAULT
        }
        if (uri.startsWith("/")) {
            uri = uri.substring(1);
        }

        return uri.includes("http") ?
            uri :BASE_IMAGE_URL + uri

    } catch (E) {
        return {
            uri: DEFAULT
        }
    }
}


export function truchateString(string, length) {
    if (!string) return ""
    if (string.length > length) {
        return string.substring(0, length) + "..."
    }
    return string
}
export  const PROPERTY_CHOICES = {
    AVAILABLE: 'available',
    SOLD: 'sold',
    UN_LISTED: 'unlisted',
}

export function getPropertyValue(key) {
    if (key in PROPERTY_CHOICES) {
        return PROPERTY_CHOICES[key]
    }

    return key

}