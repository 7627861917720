import React, {createContext, useEffect} from "react";
import AlertModal from "./AlertModal";

const initialState = {
    isAlertBoxOpen: false,
    title: "Alert",
    message: "This is an alert message",
    confirmButtonText: "Confirm",
    cancelButtonText: "Cancel",
    onConfirm: () => {
    },
    onCancel: () => {
    },
    callBackItem: null,
}

export const AlertBoxContext = createContext(initialState);


export default function AlertBoxProvider({children}) {

    const [state, setState] = React.useState(initialState);

    const open = (callBackItem) => {
        if (callBackItem)
            setState({...state, isAlertBoxOpen: true, callBackItem: callBackItem});
        else
            setState({...state, isAlertBoxOpen: true});
    }
    const close = () => {
        setState({...state, isAlertBoxOpen: false});
    }
    const toggle = () => {
        setState({...state, isAlertBoxOpen: !state.isAlertBoxOpen});
    }
    const config = (config) => {
        setState({...state, ...config});
    }
    const actions = {
        open: open,
        close: close,
        toggle: toggle,
        config: config
    }

    return (
        <AlertBoxContext.Provider value={{...state, actions}}>
            <AlertModal/>
            {children}
        </AlertBoxContext.Provider>
    )
}


export function useAlterBox(config = null) {
    const {actions, ...state} = React.useContext(AlertBoxContext);

    useEffect(() => {
        if (config) {
            actions.config(config);

        }
    }, []);

    return {alterBoxConfig: state, actions}
}


