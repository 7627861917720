import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CustomQuill.css";

const RichTextInput = ({value, onChange}) => {

    return (
        <div className={"w-full"}>
            <ReactQuill
                theme="snow"
                value={value}
                onChange={onChange}
                formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "image",
                ]}
                modules={{
                    toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                        ],
                        ["link", "image"],
                        ["clean"],
                    ],
                }}
                style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                }}
            />
        </div>
    );
};

export default RichTextInput;
