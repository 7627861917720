import Badge from "../../Badges/Badge";
import DeleteModalButton from "../delete-button/delete-modal-button";
import {MdDelete} from "react-icons/md";
import URLS from "../../../API/Axios/URLS";
import {Link} from "react-router-dom";
import {GrView} from "react-icons/gr";
import {getUri} from "../../../utils/utils";

export default function ReferralsMembershipCard({className, ...props}) {
    return (
        <div
            className={`flex dark:text-white p-3    flex-col gap-1 rounded-md shadow-md bg-gray-50 border-[1px]   dark:bg-darkBgSecondary dark:border-darkBgSecondary  ${className}`}>
            <div className={"text-center mb-2"}>
                <img
                    src={getUri(props?.user?.dp?.file)}
                    alt={"dp"}
                    className={"h-10 w-10 rounded-full mx-auto"}
                />
                <p className={"font-semibold"}>
                    {props?.user?.full_name}
                </p>
                <div className={"inline-flex items-center gap-2"}>

                    <p className={"text-sm opacity-80"}>
                        {props?.user?.email}

                    </p>
                    <Link
                        to={"/app/user/"+props?.user?.id}

                    >
                        <GrView/>
                    </Link>
                </div>
            </div>

            <div className={"inline-flex justify-between items-center"}>
                <p className={"font-semibold text-lg"}>
                    #{props?.id} {props?.package?.name} - <span className={" text-xs opacity-70"}>
                 {props?.package?.type}
             </span>
                </p>

                <p className={"font-bold"}>
                    {props?.amount}$
                </p>
            </div>
            <div className={"grid grid-cols-2 mt-3"}>
                <p className={"font-semibold"}>
                    Start Date
                </p>
                <p className={"opacity-70 text-end"}>
                    {props?.start_date}
                </p>
                <p className={"font-semibold"}>
                    End Date
                </p>
                <p className={"opacity-70 text-end"}>
                    {props?.end_date}
                </p>

            </div>

            <div className={"flex flex-row justify-between items-center     mt-3"}>
                <div className={"inline-flex gap-2 text-xs"}>
                    <p className={"font-semibold"}>
                        Created At
                    </p>
                    <p className={"opacity-70"}>
                        {props?.created_at}
                    </p>
                </div>
                <div className={"flex-1"}/>
                <div className={"flex flex-row justify-end"}>
                    {props?.currently_active ?
                        <Badge type={"SOLD"}>
                            <p className={"px-7"}>
                                Active

                            </p>

                        </Badge> :
                        <Badge type={"UN_LISTED"}>
                            <p className={"mx-7"}>
                                Inactive
                            </p>
                        </Badge>

                    }
                </div>
                <div className={"flex ml-2 flex-row justify-end"}>


                </div>
            </div>


        </div>

    )
}