import {useNavigate, useParams} from "react-router-dom";
import useForm from "../../../Hooks/useForm";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";
import FormCard from "../../../components/utils/FormCard";
import PageTitle from "../../../components/utils/PageTitle";
import FormItem from "../../../components/Form/FormItem/FormItem";
import UploadFile from "../../../API/UploadFile";
import {getUri} from "../../../utils/utils";
import FormNoneFieldErrors from "../../../components/Form/FormNoneFieldErrors";
import FormButton from "../../../components/Form/Button/FormButton";

export default function AddMaterial({className, ...props}) {
    let {id} = useParams();
    const [form, error, loading, setForm, actions] = useForm({});
    const [fetching, setFetching] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) return;
        setFetching(true)
        axiosInstance.get(URLS.MaterialCrud, {
            params: {
                id: id
            }
        }).then(res => {
            setForm(res.data?.data);
        }).catch(error => {
            toast.error(error?.response?.data?.error?.message || "An error occurred")
            navigate(-1);
        }).finally(() => setFetching(false))
    }, [])


    let SubmitForm = () => {
        if (loading || fetching) return;
        if (id) {
            actions.put(URLS.MaterialCrud, (data) => {
                return {
                    ...data,
                    file: data?.file?.id
                }
            }).then(res => {
                toast.success("Material updates successfully");
                navigate(-1);
            }).catch(error => {
                toast.error(error?.response?.data?.error?.message || "An error occurred")
            })

            return;
        }
        actions.post(URLS.MaterialCrud, (data) => {
            return {
                ...data,
                file: data?.file?.id
            }
        }).then(res => {
            toast.success("Material created successfully");
            navigate(-1);
        }).catch(error => {
            toast.error(error?.response?.data?.error?.message || "An error occurred")
        })
    };

    if (fetching) return (<div className={`w-full h-full overflow-auto ${className}`}>

        <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
            <PageTitle>
                {
                    id ? "Edit " : "Add "
                } Material
            </PageTitle>
            <p>Loading...</p>
        </FormCard>
    </div>)

    return (
        <div className={`w-full h-full overflow-auto ${className}`}>

            <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                <PageTitle>
                    {
                        id ? "Edit " : "Add "
                    } Material
                </PageTitle>
                <div className={"grid grid-cols-1  gap-2 lg:gap-4"}>
                    <FormItem
                        label={"Title"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.title}
                    >
                        <input
                            defaultValue={form?.title}
                            placeholder={"What is the title of your material?"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, title: e.target.value})
                            }}
                        />
                    </FormItem>

                    <div className={"grid grid-cols-1  gap-2 lg:gap-4"}>

                        <input
                            type={"file"}
                            className={"hidden"}

                            id={"file"}
                            onChange={(e) => {
                                UploadFile(e.target.files[0]).then(response => {
                                    setForm({...form, file: response?.data})
                                }).catch(error => {
                                    toast.error("Error uploading file")
                                    setForm({...form, file: null})
                                })
                            }}
                        />

                        <div className={"flex flex-row items-center"}>
                            {
                                form?.file?.file && <div className={""}>
                                    <a
                                        target={"_blank"}
                                        href={getUri(form?.file?.file)}
                                        className={"text-lg font-bold text-gray-500"}>
                                        View File
                                    </a>
                                </div>
                            }
                            <label className={"rounded-md p-2 "} htmlFor={"file"}>
                                <div
                                    className={"w-full   py-4 px-2  rounded-md shadow-md bg-white border-[2px] border-dashed flex justify-center items-center"}>
                                    <p className={"text-lg font-bold text-gray-500"}>
                                        Upload File
                                    </p>
                                </div>

                            </label>
                            <div className={"inline-flex gap-2"}>
                                <input
                                    checked={form?.is_free}
                                    onChange={(e) => {
                                        setForm({
                                            is_free: e.target.checked
                                        })
                                    }}
                                    type={"checkbox"}
                                />
                                <p>
                                    is Free
                                </p>
                            </div>

                        </div>
                        <FormNoneFieldErrors errors={error?.file}/>

                        <FormButton
                            loading={loading || fetching}
                            onClick={SubmitForm}

                        >
                            Submit
                        </FormButton>

                    </div>

                </div>
            </FormCard>
        </div>

    )
}