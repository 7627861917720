import './App.css';
import Root from "./screens/root";
import "./assets/css/global.css"
import {BrowserRouter} from "react-router-dom";
import AlertBoxProvider from "./Modals/AlertModal/Context";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {SessionWrapper} from "./redux/Reducers/AuthReducer";
import {useTheme} from "./redux/Reducers/ThemeReducer";

function App() {
    let {isDarkMode} = useTheme();

    return (

        <AlertBoxProvider>
            <ToastContainer
                theme={isDarkMode ? "dark" : "light"}

            />

            <BrowserRouter>
                <SessionWrapper>
                    <Root/>
                </SessionWrapper>
            </BrowserRouter>
        </AlertBoxProvider>
    );
}

export default App;
