import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";


export default function AdminLayout({className,...props}) {
 
     return (
         <div className={`w-[100vw] h-[100vh] flex flex-row bg-white dark:bg-darkTheme-900  ${className}`}>
             <Sidebar />
             <div className={"flex flex-col flex-1"}>
                 <Navbar />
                 <main className="h-full overflow-y-auto">
                     <div className="container grid px-6    mx-auto">{props.children}</div>
                 </main>


             </div>


         </div>
         
     )
 }