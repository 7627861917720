



export default [
    {
        label: "Vocabulary",
        value: "vocabulary",
    },{
        label: "Writing",
        value: "writing",
    },{
        label: "Speaking",
        value: "speaking",
    },{
        label: "Listening",
        value: "listening",
    },{
        label: "Reading",
        value: "reading",
    }
]