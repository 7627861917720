


 export default function SidebarDesktop({className,...props}) {
 
     return (
         <div className={` ${className} z-30 flex-shrink-0 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 lg:block`}>
             {
                    props.children
             }
         </div>
         
     )
 }