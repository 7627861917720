import {Route, Routes} from "react-router-dom";
import AddGuide from "./screens/addGuide";
import GuideTable from "./screens/guideTable";

export default function Guide({className, ...props}) {

    return (
        <Routes>
            <Route path="/" element={<GuideTable/>}/>
            <Route path="/add" element={<AddGuide/>}/>
            <Route path="/edit/:title" element={<AddGuide/>}/>
        </Routes>

    )
}