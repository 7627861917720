import {useAlterBox} from "./Context";
import "./alterbox.css"

export default function AlertModal({className, ...props}) {
    const {alterBoxConfig, actions} = useAlterBox();
    return (

        <div
            onClick={() => {
                actions.close();
            }}
            className={`${alterBoxConfig.isAlertBoxOpen ? " " : "hidden"} fixed inset-0 z-40 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center appear-done enter-done ${className}`}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className={`${alterBoxConfig.isAlertBoxOpen?" bottom-to-top-animation ":""} w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl appear-done enter-done`}>
                <header className="flex justify-end">
                    <button
                        onClick={() => actions.close()}
                        className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                        aria-label="close">
                        <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
                            <path
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipPath="evenodd" fillRule="evenodd"></path>
                        </svg>
                    </button>
                </header>
                <p className="mt-4 mb-2 text-xl font-semibold text-gray-700 dark:text-gray-300">{alterBoxConfig.title}</p>
                <div className="mb-6 text-base text-gray-700 dark:text-gray-400">
                    {
                        alterBoxConfig.message
                    }
                </div>
                <div className={"flex text-black dark:text-white flex-row-reverse gap-2 flex-1"}>
                    <button
                        onClick={() => {
                            alterBoxConfig.onConfirm();
                            actions.close();
                        }}
                        className={"flex-1 md:flex-none text-sm bg-purple-600 px-6 py-2 rounded-lg dark:bg-purple-700 font-semibold text-white"}>
                        {alterBoxConfig.confirmButtonText}
                    </button>
                    <button
                        onClick={() => {
                            alterBoxConfig.onCancel();
                            actions.close();
                        }}
                        className={"flex-1 md:flex-none text-sm  px-6 py-2 rounded-lg border-[1px] font-semibold "}>
                        {alterBoxConfig.cancelButtonText}
                    </button>
                </div>
            </div>
        </div>

    )
}