import PageTitle from "../../../components/utils/PageTitle";
import {BiDownload} from "react-icons/bi";
import {Link} from "react-router-dom";
import {BASE_URL} from "../../../settings/settings";
import {toast} from "react-toastify";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import URLS from "../../../API/Axios/URLS";
import {useAlterBox} from "../../../Modals/AlertModal/Context";
import {useEffect, useRef} from "react";
import useListApi from "../../../Hooks/useListApi";
import Table from "../../../components/Table/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableCell from "../../../components/Table/TableCell";
import TableBody from "../../../components/Table/TableBody";
import TableLoadingRow from "../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../components/Table/TableEmpty";
import TableRow from "../../../components/Table/TableRow";
import ReactStars from "react-rating-stars-component";
import {MdDelete, MdEditNote} from "react-icons/md";
import TableFooter from "../../../components/Table/TableFooter";
import Paginator from "../../../components/Table/Paginator";
import TableContainer from "../../../components/Table/TableContainer";
import Avatar from "../../../components/Image/Avatar/Avatar";
import {getUri} from "../../../utils/utils";
import Badge from "../../../components/Badges/Badge";
import {useSelector} from "react-redux";

export default function UserTable({className, ...props}) {

    const isFirstTime = useRef(true);
    let [userData, filter, isLoading, userActions] = useListApi(URLS.UserList, {
        page_size: 25,
        search: ""
    });

    const search = useSelector(state => state.SearchInputSlice).text;


    useEffect(() => {
        if (isFirstTime.current) {
            isFirstTime.current = false;
            return;
        }

        userActions.updateFilter({
            search: search
        });

    }, [search]);


    function downloadEmails() {
        toast.promise(axiosInstance.get(URLS.emailDownload, {
            responseType: 'blob'
        }), {
            loading: "Downloading...",
            success: "Emails Downloaded",
            error: "Failed to download emails"
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'users.csv'); // or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(err => {

        })
    }


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            <PageTitle>
                Users
            </PageTitle>
            <div className={"w-full flex mb-10 justify-end items-center"}>
                <button title={"Download Emails"}
                        className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                        onClick={downloadEmails}
                >
                    <BiDownload className={"text-base"}/>
                </button>
            </div>

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>No</TableCell>
                            <TableCell>dp</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Date Joined</TableCell>
                            <TableCell>country</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Membership</TableCell>
                            <TableCell>Actions</TableCell>
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {userData?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && userData?.results.map((users, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <p className="text-sm text-gray-600  dark:text-gray-400">{i + 1}</p>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <Avatar
                                            src={getUri(users?.dp)}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <div>
                                            <p className="text-base text-gray-600  dark:text-gray-400">{users?.full_name}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{users?.email}</p>

                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{users?.date_joined}</p>

                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{users?.country || "-"}</p>
                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">
                                        {users?.is_staff ?
                                            <Badge type={"SOLD"}>
                                                <p className={"mx-4"}>
                                                    Admin

                                                </p>

                                            </Badge> :
                                            <Badge type={"UN_LISTED"}>
                                                <p className={"mx-4"}>

                                                    User
                                                </p>
                                            </Badge>

                                        }

                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">
                                        {users?.has_active_membership ?
                                            <Badge type={"SOLD"}>
                                                <p className={"mx-4"}>
                                                    Active

                                                </p>

                                            </Badge> :
                                            <Badge type={"UN_LISTED"}>
                                                <p className={"mx-4"}>
                                                    Inactive
                                                </p>
                                            </Badge>

                                        }

                                    </p>
                                </TableCell>
                                <TableCell>
                                    <div className={"flex gap-2"}>
                                        <Link to={`/app/user/${users.id}`}>
                                            <MdEditNote
                                                title={"edit"} className={"cursor-pointer"}/>
                                        </Link>
                                        {/*<MdDelete title={"delete"}*/}
                                        {/*          onClick={() => {*/}

                                        {/*          }}*/}
                                        {/*          className={"cursor-pointer"}/>*/}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {userData?.count} entries
                    </p>
                    <Paginator
                        currentPage={userData?.currentPage}
                        totalPages={userData?.totalPages}
                        onNext={(e) => {
                            userActions.loadNextPage()
                        }}
                        onPrev={(e) => userActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            userActions.loadSpecificPage(e)
                        }}
                    />

                </TableFooter>

            </TableContainer>
        </div>
    )
}