import MenuDropdown from "../../dropdown/MenuDropdown/MenuDropdown";
import {useSession} from "../../../redux/Reducers/AuthReducer";
import {NavLink, useNavigate} from "react-router-dom";
import {getUri} from "../../../utils/utils";

export default function ProfileMenu({className, ...props}) {
    const {session, authActions} = useSession();
    const navigate = useNavigate()
    return (<MenuDropdown



            button={<img
                className={`${className} h-8 w-8 cursor-pointer rounded-full`}
                src={getUri(session?.profile?.dp_uri?.file)}
                alt="Elon Musk"
            />}
            classNames={"py-2 top-8 -left-[200px] w-max"}
        >
            <div
                className="flex w-56 flex-col  justify-start rounded-lg bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-darkTheme-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                    <div className="flex items-center gap-2">
                        <p className="text-sm text-center whitespace-nowrap font-bold text-navy-700 dark:text-white">
                            👋
                            {session?.profile?.full_name?.length > 15 ? session?.profile?.full_name?.substring(0, 15) + "..." : session?.profile?.full_name}
                        </p>{" "}
                    </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 "/>

                <div className="flex flex-col p-4">
                    <NavLink
                      to={"/app/settings"}
                        className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                    >
                        Profile Settings
                    </NavLink>

                    <div
                        onClick={() => {
                            authActions.clearSession();
                            navigate("/auth/login")
                        }}
                        className="mt-3 cursor-pointer text-sm font-medium text-red-500 hover:text-red-500"
                    >
                        Log Out
                    </div>
                </div>
            </div>

        </MenuDropdown>

    )
}