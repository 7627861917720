import {BASE_URL} from "../../settings/settings";


const URLS = {
    BASE_URL: BASE_URL,
    login: 'auth/api-token-auth/',
    ResetPassword: 'auth/reset-password',
    ValidateOtp: 'auth/validate-otp',
    SetNewPassword: 'auth/set-new-password',
    UploadFile: 'auth/upload-file',
    ChangePassword: 'auth/change-password',


    dashboard: "dashboard",
    updateProfile: 'auth/update-profile',
    ListNewsLetter: 'news-letter-subscriber',

//     guide
    guide: "guide",
    guideList: "guide-list",

//     blogs
    blogs: "blogs",
    blogList: "blogs-meta",

// email download
    emailDownload: "get-user-emails",
    UserList: "user-list",
    UserInfo: "user/",
    UserMemberships: "user-memberships/",
    UserMembershipsCrud: "user-memberships-crud",
    ReferalUserMemberships: "referal-membership-list/",


    //Material
    MaterialCrud: "material-crud",
    MaterialList: "material-list",
    Packages:"packages-list"
}


export default URLS;