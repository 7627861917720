import PageTitle from "../../components/utils/PageTitle";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableCell from "../../components/Table/TableCell";
import TableBody from "../../components/Table/TableBody";
import TableLoadingRow from "../../components/Table/TableLoadingRow";
import TableEmpty from "../../components/Table/TableEmpty";
import TableRow from "../../components/Table/TableRow";
import ReactStars from "react-rating-stars-component";
import {Link} from "react-router-dom";
import {MdDelete, MdEditNote} from "react-icons/md";
import TableFooter from "../../components/Table/TableFooter";
import Paginator from "../../components/Table/Paginator";
import TableContainer from "../../components/Table/TableContainer";
import {useAlterBox} from "../../Modals/AlertModal/Context";
import {useRef} from "react";
import useListApi from "../../Hooks/useListApi";
import URLS from "../../API/Axios/URLS";
import axiosInstance from "../../API/Axios/AxiosConfig";
import {toast} from "react-toastify";


export default function NewsLetterSubscriber({className, ...props}) {

   
    let [data, filter, isLoading, guideActions] = useListApi(URLS.ListNewsLetter);


   
    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            <PageTitle>
                News Letter Subscriber
            </PageTitle>

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Email</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>

                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {data?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && data?.results.map((users, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <div>
                                            <p className="text-base text-gray-600  dark:text-gray-400">{users.email}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{users?.created_at}</p>

                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{users?.created_time}</p>
                                </TableCell>
                               
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {data?.count} entries
                    </p>
                    <Paginator
                        currentPage={data?.currentPage}
                        totalPages={data?.totalPages}
                        onNext={(e) => {
                            guideActions.loadNextPage()
                        }}
                        onPrev={(e) => guideActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            guideActions.loadSpecificPage(e)
                        }}
                    />

                </TableFooter>

            </TableContainer>
        </div>

    )
}