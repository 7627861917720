import {useState} from "react";
import Suggestion from "../variables/SugesstionInput";
import {useDispatch, useSelector} from "react-redux";
import {setSearchInput} from "../../../redux/Reducers/SearchBarSlice";

function SugesstionItem({className, id, title, onClick, ...props}) {

    return (
        <p onClick={() => onClick(id)} className={`px-3 py-1  ${className} cursor-pointer`}>
            {
                title
            }
        </p>

    )
}

export default function SearchInput({className, ...props}) {
     const [searchResults, setSearchResults] = useState([])
    const search = useSelector(state => state.SearchInputSlice).text;
    const dispatch = useDispatch();
    function setSearch(value) {
        dispatch(setSearchInput(value))
    }

     return (
        <div className="flex justify-center flex-[4] lg:mr-32">
            <div className="relative w-full max-w-xl mr-6 text-purple-500">
                <div className="absolute inset-y-0 flex items-center pl-2">
                    <svg className="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"></path>
                    </svg>
                </div>
                <input
                    className="w-full pl-8 pr-2 text-sm py-2 text-gray-700
                  placeholder-gray-600 bg-gray-100 border-0 rounded-md
                  dark:placeholder-gray-500 dark:focus:outline-gray-900
                  dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200
                  focus:placeholder-gray-500  focus:border-purple-800
                  focus:outline-purple-300"
                    defaultValue={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                        setSearchResults([].filter((item) => {
                            return item.toLowerCase().includes(e.target.value.toLowerCase())
                        }))
                    }}
                    type="text" placeholder="Search for projects"/>
                {/*{*/}
                {/*    search.length > 0 ?*/}

                {/*        <div*/}
                {/*            className={"absolute w-full max-h-[50vh] suggestion-main overflow-y-auto bg-white dark:bg-darkTheme-700 mt-2 shadow-xl rounded-md"}>*/}
                {/*            <div className={"flex suggestion flex-col"}>*/}

                {/*                {*/}
                {/*                    searchResults.map((item, index) => {*/}
                {/*                        return (*/}
                {/*                            <SugesstionItem*/}
                {/*                                onClick={(id) => {*/}
                {/*                                    // setSearch(item.title)*/}
                {/*                                    // setSearchResults([])*/}
                {/*                                }}*/}
                {/*                                key={index}*/}
                {/*                                id={index}*/}
                {/*                                title={item}*/}
                {/*                            />*/}
                {/*                        )*/}
                {/*                    })*/}
                {/*                }*/}


                {/*            </div>*/}
                {/*        </div> : null}*/}
            </div>
        </div>

    )
}