import {useParams} from "react-router-dom";
import useGet from "../../../Hooks/useGet";
import URLS from "../../../API/Axios/URLS";
import ViewProfile from "./profile-detail-card";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import {toast} from "react-toastify";

import Memberships from "./memberships";
import TabSwitch from "../../../components/TabSwitch/TabSwitch";
import {useEffect, useState} from "react";
import Referrals from "./referrals";

export default function UserProfile({className, ...props}) {
    const {id} = useParams();
    const [data, loading, error, loadData] = useGet(URLS.UserInfo + id);
    const [selectedTab, setSelectedTab] = useState("memberships")

    function onRoleChange(e) {
        axiosInstance.put(URLS.UserInfo + id, {
            role: e.target.value
        }).then(res => {
            toast.success(res?.data?.message, {
                toastId: "role-change"
            });
            loadData()
        }).catch(err => {
            console.log(err)
        })
    }


    useEffect(() => {
        loadData()
    }, [id]);


    if (loading) {
        return <div>Loading...</div>
    }
    return (
        <div className={` ${className} px-8 flex flex-col gap-3 py-8`}>
            <ViewProfile user={data} onRoleChange={onRoleChange}/>

            <div className={"mt-4"}>
                <div className={"lg:w-1/3"}>
                    <TabSwitch

                        options={[
                            {
                                label: "Memberships",
                                value: "memberships",
                            }, {
                                label: "Referrals",
                                value: "Referrals",
                            }
                        ]}

                        defaultSelected={selectedTab}
                        onOptionChange={(value) => {
                            setSelectedTab(value)
                        }}


                    />
                </div>
            </div>
            {
                selectedTab === "memberships" ? <Memberships id={id}/> : <Referrals id={id} />
            }

        </div>

    )
}