import React from 'react'

function Chart({children,className, title}) {
    return (
        <div className={`${className} min-w-0 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800`}>
            <p className="mb-4 font-semibold text-gray-800 dark:text-gray-300">{title}</p>
            {children}
        </div>
    )
}

export default Chart
