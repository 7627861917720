import {Navigate, Route, Routes} from "react-router-dom";
import UserTable from "./user-table/user-table";
import UserProfile from "./user-profile/user-profile";


export default function User({className,...props}) {
 
     return (
         <div className={` ${className}`}>
             <Routes>
                 <Route
                     path={"/user-list"}
                     element={<UserTable/>}
                 />
                 <Route
                     path={"/user-list"}
                    element={<UserTable/>}
                 /><Route
                 path={"/:id"}
                 element={<UserProfile/>}
             />

                 <Route
                    path={"*"}
                    element={<Navigate to={"/app/user/user-list"}/>}
                 />
             </Routes>

         </div>

     )
 }