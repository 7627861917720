import {Route, Routes} from "react-router-dom";
import AddMaterial from "./add-material/add-material";
import MaterialTable from "./material-table/material-table";


export default function Material({className, ...props}) {

    return (
        <Routes>
            <Route path="/" element={<MaterialTable/>}/>
            <Route path="/add" element={<AddMaterial/>}/>
            <Route path="/edit/:id" element={<AddMaterial/>}/>
        </Routes>

    )
}