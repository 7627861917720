import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
import {CgBatteryEmpty} from "react-icons/cg";
import MembershipCard from "../../../components/base/membership-card/membership-card";
import {useEffect, useState} from "react";
import {BiPlus} from "react-icons/bi";
import NewMembershipModal from "../../../Modals/new-membership-modal/new-membership-modal";


export default function Memberships({className, id, ...props}) {
    const [memberships, filter, isMemberShipLoading, actions] = useListApi(URLS.UserMemberships + id, {
        page_size: 1000
    });
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        actions.load()
    }, [id]);

    return (
        <div className={` ${className} flex flex-col gap-8`}>
            <NewMembershipModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSuccess={() => {
                    actions.load()
                }}
                user_id={id}
            />
            <div className={"inline-flex justify-between"}>

                <p className={"font-semibold dark:text-white text-lg mt-8"}>
                    Memberships
                </p>

                <div
                    onClick={() => {
                        setIsOpen(true)
                    }}
                    className={"bg-purple-900 cursor-pointer w-10 h-10 rounded-full inline-flex justify-center items-center text-white"}>
                    <BiPlus/>
                </div>
            </div>

            {
                !isMemberShipLoading ? memberships?.results?.length > 0 ?
                    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3"}>
                        {
                            memberships?.results?.map((membership, index) => {
                                return <MembershipCard
                                    key={index}
                                    onDeleteCallBack={() => {
                                        actions.load()
                                    }}
                                    {...membership} />
                            })
                        }

                    </div> : <div className={"flex flex-col gap-2 justify-center text-xl items-center py-20 font-bold"}>
                        <CgBatteryEmpty size={30}/>
                        <p>
                            No membership found
                        </p>
                    </div> : "loading..."}
        </div>

    )
}