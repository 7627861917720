import {useEffect} from "react";
import {useDrawer} from "../../redux/Reducers/DrawerReducer";
import  {AiFillCloseCircle} from "react-icons/ai";
import logo from "../../assets/img/logo.png"


 export default function SidebarMobile({className,...props}) {
     const {isDrawerOpen, actions} = useDrawer();

     useEffect(() => {
         if (isDrawerOpen) {
             document.body.classList.add("filter-class"); // Add
             let main = document.getElementById("root");
             main.scrollIntoView({behavior: "smooth"});


         } else {
             document.body.classList.remove("filter-class"); // Add

         }

     }, [isDrawerOpen])
     return (
         <div onClick={actions.closeDrawer} className={`${!isDrawerOpen ? "hidden" : "lg:hidden"} no-selection fixed z-[1000] top-0 left-0  w-full h-full `}>

             <div onClick={(e) => e.stopPropagation()}
                  className={`  flex flex-col open-left-to-right-drawer  w-2/3 md:w-1/3  h-full dark:bg-gray-800 bg-white shadow-lg`}>
                 {
                     props.children
                 }

             </div>
         </div>
         
     )
 }