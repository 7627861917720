import TableRow from "./TableRow";
import TableCell from "./TableCell";
import ThreeDotsLoader from "../Animaiton/ThreeDotsLoader/ThreeDotsLoader";


export default function TableLoadingRow({className,colSpan,...props}) {
 
     return (
         <TableRow >
             <TableCell  className={`${className?className:"h-60"} bg-gray-100 dark:bg-darkTheme-800 animate-pulse `}       colSpan={colSpan?colSpan:100}    {...props}>
                    <ThreeDotsLoader />
             </TableCell>
         </TableRow>
         
     )
 }