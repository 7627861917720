export default function Badge({className, type, ...props}) {

    function getBadgeColor() {
        switch (type) {
            case "AVAILABLE":
                return " text-purple-700 bg-purple-100 dark:text-white dark:bg-purple-600";
            case "UN_LISTED":
                return " text-red-700 bg-red-100 dark:text-white dark:bg-red-600";
            case "SOLD":
                return " text-green-700 bg-green-100 dark:text-white dark:bg-green-600";
            case "warning":
                return " text-yellow-700 bg-yellow-100 dark:text-white dark:bg-yellow-600";
            default:
                return " text-gray-700 bg-gray-100 dark:text-white dark:bg-gray-600";
        }

    }


    return (
        <span
            className={`inline-flex px-4  text-xs font-medium leading-5 w-full lg:w-1/2 justify-center items-center text-center rounded-full ${getBadgeColor()}`}>
                {
                    props.children
                }

            </span>

    )
}