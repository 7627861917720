import {GiPositionMarker} from "react-icons/gi";
import React from "react";
import {TfiMarkerAlt} from "react-icons/tfi";

import {FcGoogle} from "react-icons/fc";
import {Link} from "react-router-dom";
import DpCircle from "../../Setting/components/DpCircle";
import {ProfileBox} from "../../Setting/View";
import {CiMedicalClipboard} from "react-icons/ci";
import {getUri} from "../../../utils/utils";
import {toast} from "react-toastify";


export default function ViewProfile({className, user, onRoleChange, ...props}) {

    function getLink() {
        let url = window.location.href;
        if (url.includes("localhost")) {
            url = "http://localhost:3000/pricing/vip?referral_code=" + user?.referral_code;
        } else {
            url = "https://www.detstudy.com/pricing/vip?referral_code=" + user?.referral_code;
        }
        return url;
    }

    function copyReffralText() {

        navigator.clipboard.writeText(getLink());
        toast("Referral link copied to clipboard", {
            toastId: "referral"
        })
    }

    function copyReffralCode() {
        navigator.clipboard.writeText(user?.referral_code);
        toast("Referral code copied to clipboard", {
            toastId: "referral"
        })
    }

    return (
        <div className={`flex  flex-col gap-12 dark:text-white ${className}`}>
            <div className={"flex flex-row grid- justify-center items-center gap-3"}>
                <DpCircle src={getUri(user?.dp)}/>
                <div className={"flex flex-1 flex-col justify-start items-start gap-1"}>
                    <p className={'font-bold text-sm lg:text-xl'}>{user?.full_name}</p>
                    <p className={'font-normal text-xs lg:text-sm text-gray-400 flex flex-row justify-center items-center'}>
                        <span><GiPositionMarker className={"text-tertiary-900"}/></span>
                        {user?.country || "-"}
                    </p>
                </div>

                <div className={"flex-col flex justify-center items-center  p-4"}>


                </div>

            </div>
            <div
                className={"grid grid-cols-1  lg:grid-cols-2 rounded-xl border-[1px] dark:border-darkBgSecondary bg-gray-100 dark:bg-darkBgTertiary "}>

                <ProfileBox heading={"Account Type"} className={"border-b-[1px] border-r-[1px]"}
                            endIcon={() => {
                                return <div className={"rounded-md border-[1px]   px-2 py-1 cursor-pointer"}>
                                    <select
                                        className={"dark:bg-darkBgTertiary"}
                                        onChange={onRoleChange}
                                        value={user?.is_staff ? "admin" : "user"}
                                    >
                                        <option value={"admin"}>Admin</option>
                                        <option value={"user"}>User</option>
                                    </select>
                                </div>
                            }}

                />
                <ProfileBox heading={"Membership status"} className={"border-b-[1px] border-r-[1px]"}
                            value={user?.has_active_membership ? "Active" : "non-active"}/>
                <ProfileBox heading={"Email"} className={"border-b-[1px]  border-r-[1px]"} value={user?.email}/>
                <ProfileBox heading={"Date Joined"} className={"border-b-[1px] "} value={user?.date_joined}/>
                <ProfileBox heading={"Country"} className={"  border-r-[1px]"} value={user?.country || "-"}/>
                <ProfileBox heading={"Refer Code"}
                            endIcon={
                                () => <div onClick={copyReffralCode}
                                           className={"flex cursor-pointer flex-row justify-center items-center"}>

                                    {user?.referral_code} <CiMedicalClipboard
                                    className={"text-secondary-900 "}/>
                                </div>
                            }
                />
                <ProfileBox heading={"Referral Link"}
                            endIcon={
                                () => <div onClick={copyReffralText}
                                           className={"flex cursor-pointer flex-row justify-center items-center"}>

                                    {getLink()}  <CiMedicalClipboard
                                    className={"text-secondary-900 "}/>
                                </div>
                            }
                />

            </div>
        </div>

    )
}