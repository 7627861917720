import {useState} from "react";
import DeleteConfirmationModal from "../../../Modals/delete-confirmation-modal/delete-confirmation-modal";


export default function DeleteModalButton({className,highlightText, title,params, description,url, onDeleteCallBack, ...props}) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={` ${className} cursor-pointer`} onClick={() => setIsOpen(true)}>
            <DeleteConfirmationModal
                title={title}
                params={params}
                highlightText={highlightText}
                url={url}
                description={description}
                onDeleteCallBack={onDeleteCallBack}
                isOpen={isOpen} setIsOpen={setIsOpen}/>
            {
                props.children
            }
        </div>

    )
}