import {useNavigate, useParams} from "react-router-dom";
import useForm from "../../../Hooks/useForm";
import React, {useEffect, useState} from "react";
import FormCard from "../../../components/utils/FormCard";
import PageTitle from "../../../components/utils/PageTitle";
import FormItem from "../../../components/Form/FormItem/FormItem";
import ReactStars from "react-rating-stars-component";
import {WithContext as ReactTags} from "react-tag-input";
import Categories from "../../guide/variables/categories";
import RichTextInput from "../../../components/RichTextInput/RichTextInput";
import FormButton from "../../../components/Form/Button/FormButton";
import UploadFile from "../../../API/UploadFile";
import {toast} from "react-toastify";
import {getUri} from "../../../utils/utils";
import FormNoneFieldErrors from "../../../components/Form/FormNoneFieldErrors";
import URLS from "../../../API/Axios/URLS";
import axiosInstance from "../../../API/Axios/AxiosConfig";

export default function NewBlog({className, ...props}) {
    let {title} = useParams();
    const [form, error, loading, setForm, actions] = useForm({});
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);


    useEffect(() => {
        if (!title) return;
        setFetching(true)
        axiosInstance.get(URLS.blogs, {
            params: {
                title: title
            }
        }).then(res => {
            setForm(res.data?.data);
        }).catch(error => {
            if (error?.response?.status === 404) {
                toast.error("Blog not found", {
                    toastId: "blog-error"

                })
            } else
                toast.error(error?.response?.data?.error?.message || "An error occurred", {
                    toastId: "blog-error"
                })
            navigate("/app/blogs");
        }).finally(() => setFetching(false))
    }, [])

    function SubmitForm() {
        if (loading || fetching) return;
        if (title) {
            actions.put(URLS.blogs, (data) => {
                return {
                    ...data,
                    thumbnail: data?.thumbnail?.id
                }
            }).then(res => {
                toast.success("Blog update successfully", {
                    toastId: "blog-error"

                });
                navigate(-1);

            }).catch(error => {

                toast.error(error?.response?.data?.error?.message || "An error occurred", {
                    toastId: "blog-error"

                })
            })
        } else {
            actions.post(URLS.blogs, (data) => {
                return {
                    ...data,
                    thumbnail: data?.thumbnail?.id
                }
            }).then(res => {
                toast.success("Blog created successfully", {
                    toastId: "blog-error"

                });
                navigate(-1);

            }).catch(error => {

                toast.error(error?.response?.data?.error?.message || "An error occurred", {
                    toastId: "blog-error"

                })
            })
        }

    }

    if (fetching)
        return (
            <div className={`w-full h-full overflow-auto ${className}`}>
                <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                    <PageTitle>
                        {
                            title ? "Update " : "New "
                        } Blog
                    </PageTitle>
                    <p>Loading...</p>
                </FormCard>
            </div>
        )


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                <PageTitle>
                    {
                        title ? "Update " : "New "
                    } Blog
                </PageTitle>

                <div className={"grid grid-cols-1  gap-2 lg:gap-4"}>

                    <input
                        type={"file"}
                        className={"hidden"}

                        accept={"image/*"}
                        id={"thumbnail"}
                        onChange={(e) => {
                            UploadFile(e.target.files[0]).then(response => {
                                setForm({...form, thumbnail: response?.data})
                            }).catch(error => {
                                toast.error("Error uploading file")
                                setForm({...form, thumbnail: null})
                            })
                        }}
                    />

                    <label className={"rounded-md p-2 "} htmlFor={"thumbnail"}>
                        {
                            form?.thumbnail ? <img
                                    className={"w-full h-[300px] rounded-md shadow-md object-cover"}
                                    src={getUri(form?.thumbnail?.file)}
                                    alt={"thumbnail"}
                                /> :
                                <div
                                    className={"w-full h-[300px]  rounded-md shadow-md bg-white border-[2px] border-dashed flex justify-center items-center"}>
                                    <p className={"text-lg font-bold text-gray-500"}>
                                        Upload Thumbnail
                                    </p>
                                </div>

                        }
                    </label>
                    <FormNoneFieldErrors errors={error?.thumbnail}/>
                    <FormItem
                        label={"Title"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.title}
                    >
                        <input
                            defaultValue={form?.title}
                            placeholder={"What is the title of your guide?"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, title: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Meta Description"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.meta_description}
                    >
                        <textarea
                            defaultValue={form?.meta_description}
                            placeholder={"Write meta description for the blog."}
                            required={true}
                            onChange={(e) => {
                                setForm({...form, meta_description: e.target.value})
                            }}
                        ></textarea>
                    </FormItem>


                    <FormItem
                        label={"Content"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.content}
                    >
                        <RichTextInput
                            onChange={(value) => {
                                setForm({content: value})
                            }}
                            value={form?.content}

                        />
                    </FormItem>
                </div>

                <FormButton
                    loading={loading || fetching}
                    onClick={SubmitForm}

                >
                    {
                        title ? "Update " : "Add "
                    } Blog
                </FormButton>
            </FormCard>

        </div>

    )
}