import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
 import {CgBatteryEmpty} from "react-icons/cg";
import ReferralsMembershipCard from "../../../components/base/membership-card/referrals-membership-card";
import {useEffect} from "react";

export default function Referrals({className, id, ...props}) {
    const [memberships, filter, isMemberShipLoading, actions] = useListApi(URLS.ReferalUserMemberships + id, {
        page_size: 1000
    });

    useEffect(() => {
        actions.load()
    }, [id]);

    return (
        <div className={` ${className} flex flex-col gap-8`}>
            <p className={"font-semibold dark:text-white text-lg mt-8"}>
                Memberships referred by the user
            </p>

            {
                !isMemberShipLoading ? memberships?.results?.length > 0 ?
                    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3"}>
                        {
                            memberships?.results?.map((membership, index) => {
                                return <ReferralsMembershipCard
                                    key={index}
                                    onDeleteCallBack={() => {
                                        actions.load()
                                    }}
                                    {...membership} />
                            })
                        }

                    </div> : <div className={"flex flex-col gap-2 justify-center text-xl items-center py-20 font-bold"}>
                        <CgBatteryEmpty size={30}/>
                        <p>
                            No membership found
                        </p>
                    </div> : "loading..."}
        </div>
    )
}