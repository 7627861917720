


 export default function TableHeader({className,...props}) {

     return (
         <thead className={` ${className} text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-darkTheme-700 bg-gray-50 dark:text-darkTheme-400 dark:bg-darkTheme-800`}>
                {
                    props.children
                }
         </thead>

     )
 }