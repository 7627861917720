import PageTitle from "../../../components/utils/PageTitle";
import FormCard from "../../../components/utils/FormCard";
import FormItem from "../../../components/Form/FormItem/FormItem";
import RichTextInput from "../../../components/RichTextInput/RichTextInput";
import React, {useEffect, useState} from "react";
import Categories from "../variables/categories";
import ReactStars from "react-rating-stars-component";
import {WithContext as ReactTags} from 'react-tag-input';
import "../index.css"
import useForm from "../../../Hooks/useForm";
import FormButton from "../../../components/Form/Button/FormButton";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";
import {useNavigate, useNavigation, useParams} from "react-router-dom";
import axiosInstance from "../../../API/Axios/AxiosConfig";

const suggestions = [].map(item => {
    return {
        id: item?.toLowerCase(), text: item?.toLowerCase()
    };
});
const KeyCodes = {
    comma: 188, enter: 13
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

function ArrayToTags(array) {
    if (!array) return [];
    return array.map(item => {
        return {
            id: item?.toLowerCase(), text: item?.toLowerCase()
        };
    });
}

export default function AddGuide({className, ...props}) {
    let {title} = useParams();

    const [form, error, loading, setForm, actions] = useForm({});
    let tags = ArrayToTags(form?.subScores);
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);

    function setTags(tags) {
        setForm({subScores: tags})
    }

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        if (form?.subScores)
            setTags([...form?.subScores, tag?.text]);
        else
            setTags([tag?.text]);
    };

    useEffect(() => {
        if (!title) return;
        setFetching(true)
        axiosInstance.get(URLS.guide + "/" + title).then(res => {
            setForm(res.data?.data);
        }).catch(error => {
            toast.error(error?.response?.data?.error?.message || "An error occurred")
            navigate(-1);
        }).finally(() => setFetching(false))
    }, [])

    let SubmitForm = () => {
        if (loading || fetching) return;
        if (title) {
            actions.put(URLS.guide, (data) => {
                return {
                    ...data,
                    subScores: JSON.stringify(data.subScores)
                }
            }).then(res => {
                toast.success("Guide updates successfully");
                navigate(-1);
            }).catch(error => {
                toast.error(error?.response?.data?.error?.message || "An error occurred")
            })

            return;
        }
        actions.post(URLS.guide, (data) => {
            return {
                ...data,
                subScores: JSON.stringify(data.subScores)
            }
        }).then(res => {
            toast.success("Guide created successfully");
            navigate(-1);

        }).catch(error => {

            toast.error(error?.response?.data?.error?.message || "An error occurred")
        })
    };


    if (fetching) return (<div className={`w-full h-full overflow-auto ${className}`}>

        <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
            <PageTitle>
                {
                    title ? "Edit " : "Add "
                } Guide
            </PageTitle>
            <p>Loading...</p>
        </FormCard>
    </div>)

    return (
        <div className={`w-full h-full overflow-auto ${className}`}>

            <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                <PageTitle>
                    {
                        title ? "Edit " : "Add "
                    } Guide
                </PageTitle>

                <div className={"grid grid-cols-1  gap-2 lg:gap-4"}>
                    <FormItem
                        label={"Title"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.title}
                    >
                        <input
                            defaultValue={form?.title}
                            placeholder={"What is the title of your guide?"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, title: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Directions"}
                        type={"error"}

                        variant={"auth"}
                        message={error?.directions}
                    >
                        <textarea
                            defaultValue={form?.directions}
                            placeholder={"What are the directions to the guide?"}
                            required={true}
                            onChange={(e) => {
                                setForm({...form, directions: e.target.value})
                            }}
                        ></textarea>
                    </FormItem>
                    <label
                        htmlFor={"Importance"}
                        className={`text-sm ml-1.5 font-medium text-gray-500 dark:text-white `}
                    >
                        Importance
                    </label>

                    <ReactStars
                        id={"Importance"}
                        value={form?.importance}

                        onChange={(newRating) => {
                            setForm({...form, importance: newRating})
                        }}
                        count={5}
                        size={24}
                        classNames={"ml-1.5 -mt-4 "}
                        activeColor="#ffd700"
                    />
                    {
                        error?.importance && <p className={"text-red-500 text-xs"}>{error?.importance}</p>
                    }

                    <label
                        htmlFor={"Importance"}
                        className={`text-sm ml-1.5 font-medium text-gray-500 dark:text-white `}
                    >
                        Subscores
                    </label>
                    <ReactTags
                        tags={tags}
                        suggestions={suggestions}
                        className={"bg-transparent w-full"}
                        delimiters={delimiters}
                        handleDrag={() => {
                        }}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        inputFieldPosition="bottom"
                        autocomplete
                    />

                    {
                        error?.subScores && <p className={"text-red-500 text-xs"}>{error?.subScores}</p>
                    }

                    <FormItem
                        label={"Category"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.category}
                    >
                        <select
                            defaultValue={form?.category}
                            required={true}
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer"}
                            onChange={(e) => {
                                setForm({...form, category: e.target.value})
                            }}
                        >
                            {
                                !form?.category && <option value={""}>Select a category</option>
                            }
                            {Categories.map((category, index) => {
                                return (<option key={index} value={category.value}>{category.label}</option>)
                            })}
                        </select>
                    </FormItem>


                    <FormItem
                        label={"Description"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.description}
                    >
                        <RichTextInput
                            onChange={(value) => {
                                setForm({description: value})
                            }}
                            value={form?.description}

                        />
                    </FormItem>
                </div>

                <FormButton
                    loading={loading || fetching}
                    onClick={SubmitForm}

                >
                    Create Guide
                </FormButton>
            </FormCard>

        </div>

    )
}