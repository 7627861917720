import {Navigate, Route, Routes} from "react-router-dom";
import ViewProfile from "./View";
import UpdateProfile from "./UpdateProfile";


export default function SettingIndex({className,...props}) {

     return (
         <div className={`flex flex-col  justify-center  items-center py-[4rem]  lg:pt-[4rem] px-5 ${className}`}>
             <div className={"shadow-lg bg-white  dark:bg-darkBgSecondary dark:text-white dark:text-opacity-60 rounded-xl p-4 w-full lg:w-2/3 "}>
                 <Routes>
                        <Route path={"/"} element={<ViewProfile/>}/>
                        <Route path={"/edit"} element={<UpdateProfile/>}/>
                        <Route path={"/*"} element={<Navigate to={"/app/settings/"} /> }/>
                 </Routes>

             </div>
         </div>

     )
 }