import Spinner from "../../Loaders/Spinner/Spinner";

export default function FormButton({className,loading,text, ...props}) {

    return (
        <button

            disabled={loading}
            className={`${className} flex  justify-center items-center linear mt-2 w-full cursor-pointer rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
            {...props}>
            {
                loading ? <Spinner aria-label="Default status example"/> : props.children
            }
        </button>

    )
}