import {Navigate, Route, Routes} from "react-router-dom";
import BlogPage from "./pages/blog-page";
import NewBlog from "./pages/new-blog";


function BlogView() {
    return null;
}

export default function BlogsEntry({className,...props}) {

     return (
        <Routes>
            <Route path="/" element={<BlogPage />} />
            <Route path="/new-blog" element={<NewBlog />} />
            <Route path="/update/:title" element={<NewBlog />} />

            <Route path={"/*"} element={<Navigate  to={"/app/blogs"} />} />
        </Routes>

     )
 }