import {useEffect, useState} from "react";

function Button({className, option, selected, ...props}) {
    const isSelected = option.value === selected;


    return (
        <div
            className={`w-[130px] relative   py-1 lg:w-[200px] lg:py-2 font-semibold  text-center cursor-pointer  rounded-full flex-1   ${className}`} {...props}>
            {isSelected && (
                <span
                     className={`${isSelected?" bg-purple-900  text-white":"bg-transparent"} justify-center items-center flex  absolute inset-0 z-10  `}
                    style={{ borderRadius: 9999 }}
                 >
                    <div className={"justify-center items-center flex text-center"}>
                        {
                            option.label
                        }
                    </div>
                </span>
            )}
            {
                option.label
            }
        </div>

    )
}

export default function TabSwitch({className, options, onOptionChange, defaultSelected, ...props}) {
    const [selected, setSelected] = useState(defaultSelected ? defaultSelected : options[0].value);
    const [isMounted, setIsMounted] = useState(false)
    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true)
            return
        }
        if (onOptionChange)
            onOptionChange(selected)
    }, [selected])

    return (
        <div className={`rounded-full shadow-punch bg-[#F5F5F5] flex flex-row  ${className}`}>
            {
                options.map((option, index) => {
                    return (
                        <Button key={index} onClick={() => {
                            setSelected(option.value)
                        }} selected={selected} option={option} className={""}/>
                    )
                })
            }
        </div>

    )
}