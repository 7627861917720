

 export default function TableFooter({className,...props}) {
 
     return (
         <div className={`py-2 flex w-full flex-row dark:bg-darkTheme-800 bg-white border-t-[1px] dark:border-gray-700 ${className}`}>
             {
                    props.children
             }
         </div>
         
     )
 }