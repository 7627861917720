import TableRow from "./TableRow";
import TableCell from "./TableCell";
import ThreeDotsLoader from "../Animaiton/ThreeDotsLoader/ThreeDotsLoader";
import {PiMaskSadFill} from "react-icons/pi";


export default function TableEmpty({className, title, colSpan, ...props}) {

    return (
        <TableRow>
            <TableCell className={`${className ? className : "h-60"}  `}
                       colSpan={colSpan ? colSpan : 100}    {...props}>
                {
                    props.children ? props.children :
                        <div className={"w-full flex flex-col gap-2 justify-center items-center"}>
                            <PiMaskSadFill className={"text-4xl"}/>
                            {
                                <p className={"font-semibold text-2xl"}>
                                    {
                                        title ? title : "Nothing Found"
                                    }
                                </p>
                            }
                        </div>
                }
            </TableCell>
        </TableRow>

    )
}